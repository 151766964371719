import { QuizDto } from '../../../models/dtos/quiz.dto';
import axios from '../../axios';

export default async function startQuizRequest({
  quizId,
}: {
  quizId: number;
}): Promise<QuizDto> {
  const response = await axios.post<QuizDto>(
    `${process.env.REACT_APP_BASE_URL}/quiz/${quizId}/start`
  );
  return response.data;
}
