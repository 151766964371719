import axios from '../../axios';

export default async function sendTeamsRequest(
  teamId: number
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/users/teams`,
    {
      teamId
    }
  );
  return response.data;
}
