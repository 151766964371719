import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';
import CookieAgreement from '../../CookieAgreement/CookieAgreement';
import Footer from '../../Footer/Footer';
import Header from '../../Landing/Header';

function PublicLayout() {
  return (
    <Layout className="bg-white">
      <Header />
      <Outlet />
      <Footer />
      <CookieAgreement />
    </Layout>
  );
}

export default PublicLayout;
