import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryDto } from '../../../models/dtos/country.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getCountriesRequest from './getCountries.repository';

type InitialState = {
  loading: boolean;
  data?: CountryDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getCountries = createAsyncThunk('getCountries', () => {
  return getCountriesRequest();
});

const getCountriesSlice = createSlice({
  name: 'getCountries',
  initialState,
  reducers: {
    getCountriesReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getCountries.fulfilled,
      (state, action: PayloadAction<CountryDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getCountriesSlice.reducer;
export const { getCountriesReset } = getCountriesSlice.actions;
