import { CountryDto } from '../../../models/dtos/country.dto';
import axios from '../../axios';

export default async function getCountriesRequest(
  name: string = 'Europe'
): Promise<CountryDto[]> {
  const response = await axios.get<CountryDto[]>(
    `${process.env.REACT_APP_BASE_URL}/continents/${name}/countries`
  );
  return response.data;
}
