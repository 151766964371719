import { combineReducers, configureStore } from '@reduxjs/toolkit';
import checkTokenReducer from './app/checkToken/checkToken.slice';
import setApiErrorReducer from './app/setApiError/setApiError.slice';
import setAppMountedReducer from './app/setAppMounted/setAppMounted.slice';
import setMeReducer from './app/setMe/setMe.slice';
import setPathnameReducer from './app/setPathname/setPathname.slice';
import loginReducer from './auth/login/login.slice';
import otpReducer from './auth/otp/otp.slice';
import contactUsReducer from './base/contactUs/contactUs.slice';
import getAvatarsReducer from './base/getAvatars/getAvatars.slice';
import getCountriesReducer from './base/getCountries/getCountries.slice';
import getLeaguesReducer from './base/getLeagues/getLeagues.slice';
import getTeamsReducer from './base/getTeams/getTeams.slice';
import setRemoteConfigReducer from './firebase/setRemoteConfig/setRemoteConfig.slice';
import getLeaderboardReducer from './leaderboard/getLeaderboard/getLeaderboard.slice';
import getLeaderboardAllTeamsReducer from './leaderboard/getLeaderboardAllTeams/getLeaderboardAllTeams.slice';
import getLeaderboardAllTribunesReducer from './leaderboard/getLeaderboardAllTribunes/getLeaderboardAllTribunes.slice';
import getLeaderboardLastMonthReducer from './leaderboard/getLeaderboardLastMonth/getLeaderboardLastMonth.slice';
import getLeaderboardUserReducer from './leaderboard/getLeaderboardUser/getLeaderboardUser.slice';
import cancelSubReducer from './payment/cancelSub/cancelSub.slice';
import mobilePaymentCheckReducer from './payment/mobilePaymentCheck/mobilePaymentCheck.slice';
import mobilePaymentStartReducer from './payment/mobilePaymentStart/mobilePaymentStart.slice';
import paymentStartReducer from './payment/paymentStart/paymentStart.slice';
import answerQuizReducer from './quiz/answerQuiz/answerQuiz.slice';
import finishQuizReducer from './quiz/finishQuiz/finishQuiz.slice';
import getActiveQuizReducer from './quiz/getActiveQuiz/getActiveQuiz.slice';
import nextQuizReducer from './quiz/nextQuiz/nextQuiz.slice';
import startQuizReducer from './quiz/startQuiz/startQuiz.slice';
import deleteUserReducer from './user/deleteUser/deleteUser.slice';
import getActiveWheelReducer from './user/getActiveWheel/getActiveWheel.slice';
import getAnnouncementsReducer from './user/getAnnouncements/getAnnouncements.slice';
import getBannersReducer from './user/getBanners/getBanners.slice';
import getBenefitsReducer from './user/getBenefits/getBenefits.slice';
import getCoinsReducer from './user/getCoins/getCoins.slice';
import getCommentsReducer from './user/getComments/getComments.slice';
import getCurrentMatchesReducer from './user/getCurrentMatches/getCurrentMatches.slice';
import getOffersReducer from './user/getOffers/getOffers.slice';
import getPackagesReducer from './user/getPackages/getPackages.slice';
import getPartnersReducer from './user/getPartners/getPartners.slice';
import getProfessionsReducer from './user/getProfessions/getProfessions.slice';
import getStatReducer from './user/getStat/getStat.slice';
import getTribuneReducer from './user/getTribune/getTribune.slice';
import getTribuneGroupReducer from './user/getTribuneGroup/getTribuneGroup.slice';
import getTriviaQuestionReducer from './user/getTriviaQuestion/getTriviaQuestion.slice';
import getUserCitiesReducer from './user/getUserCities/getUserCities.slice';
import getUserCountriesReducer from './user/getUserCountries/getUserCountries.slice';
import getUserInfoReducer from './user/getUserInfo/getUserInfo.slice';
import purchaseOfferReducer from './user/purchaseOffer/purchaseOffer.slice';
import sendCommentReducer from './user/sendComment/sendComment.slice';
import sendInviteReducer from './user/sendInvite/sendInvite.slice';
import sendNicknameReducer from './user/sendNickname/sendNickname.slice';
import sendTeamsReducer from './user/sendTeams/sendTeams.slice';
import sendTriviaQuestionAnswerReducer from './user/sendTriviaQuestionAnswer/sendTriviaQuestionAnswer.slice';
import updateUserInfoReducer from './user/updateUserInfo/updateUserInfo.slice';
const appReducer = combineReducers({
  checkToken: checkTokenReducer,
  setApiError: setApiErrorReducer,
  setAppMounted: setAppMountedReducer,
  setMe: setMeReducer,
  setPathname: setPathnameReducer,
});

const authReducer = combineReducers({
  login: loginReducer,
  otp: otpReducer,
});

const baseReducer = combineReducers({
  getCountries: getCountriesReducer,
  getTeams: getTeamsReducer,
  contactUs: contactUsReducer,
  getAvatars: getAvatarsReducer,
  getLeagues: getLeaguesReducer,
});

const firebaseReducer = combineReducers({
  setRemoteConfig: setRemoteConfigReducer,
});

const leaderboardReducer = combineReducers({
  getLeaderboard: getLeaderboardReducer,
  getLeaderboardLastMonth: getLeaderboardLastMonthReducer,
  getLeaderboardUser: getLeaderboardUserReducer,
  getLeaderboardAllTeams: getLeaderboardAllTeamsReducer,
  getLeaderboardAllTribunes: getLeaderboardAllTribunesReducer,
});

const paymentReducer = combineReducers({
  mobilePaymentStart: mobilePaymentStartReducer,
  mobilePaymentCheck: mobilePaymentCheckReducer,
  paymentStart: paymentStartReducer,
  cancelSub: cancelSubReducer,
});

const quizReducer = combineReducers({
  getActiveQuiz: getActiveQuizReducer,
  startQuiz: startQuizReducer,
  answerQuiz: answerQuizReducer,
  finishQuiz: finishQuizReducer,
  nextQuiz: nextQuizReducer,
});

const userReducer = combineReducers({
  getUserInfo: getUserInfoReducer,
  sendTeams: sendTeamsReducer,
  getPackages: getPackagesReducer,
  getBanners: getBannersReducer,
  getComments: getCommentsReducer,
  sendComment: sendCommentReducer,
  getAnnouncements: getAnnouncementsReducer,
  updateUserInfo: updateUserInfoReducer,
  getProfessions: getProfessionsReducer,
  getActiveWheel: getActiveWheelReducer,
  getBenefits: getBenefitsReducer,
  getPartners: getPartnersReducer,
  sendInvite: sendInviteReducer,
  getOffers: getOffersReducer,
  purchaseOffer: purchaseOfferReducer,
  getCoins: getCoinsReducer,
  getUserCountries: getUserCountriesReducer,
  getUserCities: getUserCitiesReducer,
  getTriviaQuestion: getTriviaQuestionReducer,
  sendTriviaQuestionAnswer: sendTriviaQuestionAnswerReducer,
  sendNickname: sendNicknameReducer,
  getTribune: getTribuneReducer,
  getTribuneGroup: getTribuneGroupReducer,
  getCurrentMatches: getCurrentMatchesReducer,
  getStat: getStatReducer,
  deleteUser: deleteUserReducer,
});

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    base: baseReducer,
    firebase: firebaseReducer,
    leaderboard: leaderboardReducer,
    payment: paymentReducer,
    quiz: quizReducer,
    user: userReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
