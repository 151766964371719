import { TribuneDto } from '../../../models/dtos/tribune.dto';
import axios from '../../axios';

export default async function getTribuneRequest(
  teamId: number
): Promise<TribuneDto[]> {
  const response = await axios.get<TribuneDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/tribune`,
    {
      params: {
        teamId,
      },
    }
  );
  return response.data;
}
