import { OfferRequest } from '../../../models/requests/offer.request';
import axios from '../../axios';

export default async function purchaseOfferRequest(
  request: OfferRequest
): Promise<string> {
  const response = await axios.post<string>(
    `${process.env.REACT_APP_BASE_URL}/users/offer`,
    request
  );
  return response.data;
}
