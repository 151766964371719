import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { QuizDto } from '../../../models/dtos/quiz.dto';
import { Errors } from '../../errors';
import startQuizRequest from './startQuiz.repository';

type InitialState = {
  loading: boolean;
  data?: QuizDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const startQuiz = createAsyncThunk(
  'startQuiz',
  async (quizId: number) => {
    return await startQuizRequest({ quizId });
  }
);

const startQuizSlice = createSlice({
  name: 'startQuiz',
  initialState,
  reducers: {
    startQuizReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startQuiz.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      startQuiz.fulfilled,
      (state, action: PayloadAction<QuizDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(startQuiz.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default startQuizSlice.reducer;
export const { startQuizReset } = startQuizSlice.actions;
