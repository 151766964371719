import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommentListDto } from '../../../models/dtos/commentList.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getCommentsRequest from './getComments.repository';

type InitialState = {
  loading: boolean;
  data?: CommentListDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getComments = createAsyncThunk(
  'getComments',
  async ({ pageNumber, teamId }: { pageNumber: number; teamId?: number }) => {
    return await getCommentsRequest(pageNumber, teamId);
  }
);

const getCommentsSlice = createSlice({
  name: 'getComments',
  initialState,
  reducers: {
    getCommentsReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComments.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getComments.fulfilled,
      (state, action: PayloadAction<CommentListDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getComments.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getCommentsSlice.reducer;
export const { getCommentsReset } = getCommentsSlice.actions;
