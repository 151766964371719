import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryDto } from '../../../models/dtos/country.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getUserCountriesRequest from './getUserCountries.repository';

type InitialState = {
  loading: boolean;
  data?: CountryDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getUserCountries = createAsyncThunk('getUserCountries', () => {
  return getUserCountriesRequest();
});

const getUserCountriesSlice = createSlice({
  name: 'getUserCountries',
  initialState,
  reducers: {
    getUserCountriesReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCountries.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getUserCountries.fulfilled,
      (state, action: PayloadAction<CountryDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getUserCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getUserCountriesSlice.reducer;
export const { getUserCountriesReset } = getUserCountriesSlice.actions;
