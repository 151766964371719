import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import nextQuizRequest from './nextQuiz.repository';

type InitialState = {
  loading: boolean;
  data?: number;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const nextQuiz = createAsyncThunk('nextQuiz', async () => {
  return await nextQuizRequest();
});

const nextQuizSlice = createSlice({
  name: 'nextQuiz',
  initialState,
  reducers: {
    nextQuizReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nextQuiz.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      nextQuiz.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(nextQuiz.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default nextQuizSlice.reducer;
export const { nextQuizReset } = nextQuizSlice.actions;
