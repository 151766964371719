const ShareIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99922 8.99922C5.99922 9.99332 5.19333 10.7992 4.19922 10.7992C3.2051 10.7992 2.39922 9.99332 2.39922 8.99922C2.39922 8.0051 3.2051 7.19922 4.19922 7.19922C5.19333 7.19922 5.99922 8.0051 5.99922 8.99922ZM6.85497 10.3958C6.35256 11.3492 5.35179 11.9992 4.19922 11.9992C2.54237 11.9992 1.19922 10.6561 1.19922 8.99922C1.19922 7.34235 2.54237 5.99922 4.19922 5.99922C5.35179 5.99922 6.35256 6.64919 6.85497 7.60257L10.9243 5.05923C10.8429 4.7868 10.7992 4.49811 10.7992 4.19922C10.7992 2.54237 12.1424 1.19922 13.7992 1.19922C15.4561 1.19922 16.7992 2.54237 16.7992 4.19922C16.7992 5.85607 15.4561 7.19922 13.7992 7.19922C12.829 7.19922 11.9664 6.73867 11.418 6.02426L7.18158 8.67204C7.19324 8.77949 7.19922 8.88865 7.19922 8.99922C7.19922 9.10977 7.19324 9.21893 7.18158 9.32639L11.418 11.9742C11.9664 11.2598 12.829 10.7992 13.7992 10.7992C15.4561 10.7992 16.7992 12.1423 16.7992 13.7992C16.7992 15.456 15.4561 16.7992 13.7992 16.7992C12.1424 16.7992 10.7992 15.456 10.7992 13.7992C10.7992 13.5003 10.8429 13.2117 10.9243 12.9391L6.85497 10.3958ZM13.7992 5.99922C14.7933 5.99922 15.5992 5.19333 15.5992 4.19922C15.5992 3.2051 14.7933 2.39922 13.7992 2.39922C12.8051 2.39922 11.9992 3.2051 11.9992 4.19922C11.9992 5.19333 12.8051 5.99922 13.7992 5.99922ZM15.5992 13.7992C15.5992 14.7934 14.7933 15.5992 13.7992 15.5992C12.8051 15.5992 11.9992 14.7934 11.9992 13.7992C11.9992 12.8051 12.8051 11.9992 13.7992 11.9992C14.7933 11.9992 15.5992 12.8051 15.5992 13.7992Z"
        fill="#B1B1B1"
      />
    </svg>
  );
};

export default ShareIcon;
