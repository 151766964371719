import { Button } from 'antd';
import { ReactNode } from 'react';

interface IProps {
  block?: boolean;
  className?: string;
  children: ReactNode;
  htmlType?: 'submit';
  disabled?: boolean;
  onClick?: () => void;
}

function AppButton(props: IProps) {
  return (
    <Button
      id="app-button"
      type="primary"
      className={`h-10 rounded-lg border-0 text-base font-medium ${props.className}`}
      block={props.block}
      htmlType={props.htmlType ?? 'button'}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}

export default AppButton;
