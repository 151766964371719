import { ThemeConfig } from 'antd/es/config-provider';
import { CSSProperties } from 'react';

export class UI {
  static getTheme = (): ThemeConfig => {
    return {
      token: {
        fontFamily: 'Figtree',
        colorPrimary: '#3ACC11',
        colorBgContainerDisabled: '#3ACC1180',
        colorTextDisabled: '#fff',
        colorError: '#AF092D',
      },
      components: {
        Layout: {
          siderBg: 'transparent',
        },
        Progress: {
          remainingColor: '#F3F4F733',
          defaultColor: '#45FF1766',
          colorSuccess: '#45FF17',
        },
        Radio: {
          buttonBg: 'transparent',
          buttonCheckedBg: 'transparent',
          buttonColor: '#929292',
        },
        Form: {
          labelColor: 'white',
        },
      },
    };
  };

  static profileModalMaskStyle(): CSSProperties {
    return {
      backdropFilter: 'blur(12px)',
      WebkitBackdropFilter: 'blur(12px)',
      background: 'rgba(17, 17, 17, 0.16)',
    };
  }
}
