import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import sendNicknameRequest from './sendNickname.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const sendNickname = createAsyncThunk(
  'sendNickname',
  async (nickname: string, { rejectWithValue }) => {
    try {
      return await sendNicknameRequest(nickname);
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);

const sendNicknameSlice = createSlice({
  name: 'sendNickname',
  initialState,
  reducers: {
    sendNicknameReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendNickname.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      sendNickname.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(sendNickname.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default sendNicknameSlice.reducer;
export const { sendNicknameReset } = sendNicknameSlice.actions;
