import { LeaderboardAllDto } from '../../../models/dtos/leaderboardAll.dto';
import axios from '../../axios';

export default async function getLeaderboardAllTribunesRequest(
): Promise<LeaderboardAllDto[]> {
  const response = await axios.get<LeaderboardAllDto[]>(
    `${process.env.REACT_APP_BASE_URL}/leaderboard/tribune-group`
  );
  return response.data;
}
