import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { LeaderboardDto } from '../../../models/dtos/leaderboard.dto';
import { Errors } from '../../errors';
import getLeaderboardUserRequest from './getLeaderboardUser.repository';

type InitialState = {
  loading: boolean;
  data?: LeaderboardDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getLeaderboardUser = createAsyncThunk(
  'getLeaderboardUser',
  async (teamId?: number) => {
    return await getLeaderboardUserRequest(teamId);
  }
);

const getLeaderboardUserSlice = createSlice({
  name: 'getLeaderboardUser',
  initialState,
  reducers: {
    getLeaderboardUserReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaderboardUser.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getLeaderboardUser.fulfilled,
      (state, action: PayloadAction<LeaderboardDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getLeaderboardUser.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getLeaderboardUserSlice.reducer;
export const { getLeaderboardUserReset } = getLeaderboardUserSlice.actions;
