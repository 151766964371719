import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { LeaderboardDto } from '../../../models/dtos/leaderboard.dto';
import { Errors } from '../../errors';
import getLeaderboardRequest from './getLeaderboard.repository';

type InitialState = {
  loading: boolean;
  data?: LeaderboardDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getLeaderboard = createAsyncThunk(
  'getLeaderboard',
  async (teamId?: number) => {
    return await getLeaderboardRequest(teamId);
    // return {
    //   topAllRanks: [
    //     {
    //       surname: 'Seçilmiş',
    //       ranking: 1,
    //       points: 1600,
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 2,
    //     },
    //     {
    //       surname: 'Test',
    //       ranking: 2,
    //       points: 606,
    //       name: 'Test',
    //       id: 5,
    //     },
    //     {
    //       surname: 'Seçilmiş Aydın',
    //       ranking: 3,
    //       points: 200,
    //       avatarImageUrl:
    //         'https://static.4play.com.tr/taraftarplus/avatars/female1.png',
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 4,
    //     },
    //     {
    //       surname: 'Taşçı',
    //       ranking: 4,
    //       points: 100,
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'İncinur',
    //       id: 3,
    //     },
    //   ],
    //   topTeamRanks: [
    //     {
    //       surname: 'Seçilmiş',
    //       ranking: 1,
    //       points: 1600,
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 2,
    //     },
    //     {
    //       surname: 'Seçilmiş Aydın',
    //       ranking: 2,
    //       points: 200,
    //       avatarImageUrl:
    //         'https://static.4play.com.tr/taraftarplus/avatars/female1.png',
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 4,
    //     },
    //     {
    //       surname: 'Taşçı',
    //       ranking: 3,
    //       points: 100,
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'İncinur',
    //       id: 3,
    //     },
    //   ],
    //   userAllRanks: [
    //     {
    //       surname: 'Seçilmiş Aydın',
    //       ranking: 1,
    //       points: 200,
    //       avatarImageUrl:
    //         'https://static.4play.com.tr/taraftarplus/avatars/female1.png',
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 4,
    //     },
    //     {
    //       surname: 'Test',
    //       ranking: 2,
    //       points: 606,
    //       name: 'Test',
    //       id: 5,
    //     },
    //   ],
    //   userTeamRanks: [
    //     {
    //       surname: 'Seçilmiş Aydın',
    //       ranking: 1,
    //       points: 200,
    //       avatarImageUrl:
    //         'https://static.4play.com.tr/taraftarplus/avatars/female1.png',
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 4,
    //     },
    //     {
    //       surname: 'Seçilmiş Aydın',
    //       ranking: 2,
    //       points: 200,
    //       avatarImageUrl:
    //         'https://static.4play.com.tr/taraftarplus/avatars/female1.png',
    //       teamName: 'Fenerbahçe',
    //       teamLogoUrl:
    //         'https://static.4play.com.tr/taraftarplus/team-logos/fb.png',
    //       name: 'Meryem',
    //       id: 4,
    //     },
    //   ],
    //   lastMonthTopInTeam: {
    //     surname: 'string',
    //     ranking: 0,
    //     avatarImageUrl: 'string',
    //     teamName: 'string',
    //     points: 0,
    //     teamLogoUrl: 'string',
    //     name: 'string',
    //     id: 0,
    //   },
    //   lastMonthTop: {
    //     surname: 'string',
    //     ranking: 0,
    //     avatarImageUrl: 'string',
    //     teamName: 'string',
    //     points: 0,
    //     teamLogoUrl: 'string',
    //     name: 'string',
    //     id: 0,
    //   },
    // };
  }
);

const getLeaderboardSlice = createSlice({
  name: 'getLeaderboard',
  initialState,
  reducers: {
    getLeaderboardReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaderboard.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getLeaderboard.fulfilled,
      (state, action: PayloadAction<LeaderboardDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getLeaderboard.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getLeaderboardSlice.reducer;
export const { getLeaderboardReset } = getLeaderboardSlice.actions;
