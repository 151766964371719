import { twMerge } from 'tailwind-merge';

const PointIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={twMerge('h-6 w-6', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.32329 17.3996C7.19611 17.3996 7.09301 17.2965 7.09301 17.1693V14.551C7.09301 14.4239 6.98991 14.3208 6.86273 14.3208H4.22833C4.10115 14.3208 3.99805 14.2177 3.99805 14.0905V12.7618C3.99805 12.6346 4.10115 12.5315 4.22833 12.5315H6.86273C6.98991 12.5315 7.09301 12.4284 7.09301 12.3012V9.69903C7.09301 9.57185 7.19611 9.46875 7.32329 9.46875H8.70037C8.82755 9.46875 8.93065 9.57185 8.93065 9.69903V12.3012C8.93065 12.4284 9.03375 12.5315 9.16093 12.5315H11.7953C11.9225 12.5315 12.0256 12.6346 12.0256 12.7618V14.0905C12.0256 14.2177 11.9225 14.3208 11.7953 14.3208H9.16093C9.03375 14.3208 8.93065 14.4239 8.93065 14.551V17.1693C8.93065 17.2965 8.82755 17.3996 8.70037 17.3996H7.32329Z"
        fill="currentColor"
        fillOpacity="0.9647"
      />
      <path
        d="M11.493 17.9556C11.3658 17.9556 11.2627 17.8525 11.2627 17.7253V6.90216C11.2627 6.77497 11.3658 6.67188 11.493 6.67188H15.7117C18.3553 6.67188 19.9995 8.171 19.9995 10.4439V10.4761C19.9995 13.0069 17.9685 14.3287 15.486 14.3287H13.4757C13.3485 14.3287 13.2454 14.4318 13.2454 14.559V17.7253C13.2454 17.8525 13.1423 17.9556 13.0151 17.9556H11.493ZM13.2454 12.3091C13.2454 12.4363 13.3485 12.5394 13.4757 12.5394H15.5505C17.0496 12.5394 17.9846 11.7012 17.9846 10.5245V10.4922C17.9846 9.17041 17.0335 8.47727 15.5505 8.47727H13.4757C13.3485 8.47727 13.2454 8.58037 13.2454 8.70755V12.3091Z"
        fill="currentColor"
        fillOpacity="0.9647"
      />
    </svg>
  );
};

export default PointIcon;
