import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { PackageDto } from '../../../models/dtos/package.dto';
import { Errors } from '../../errors';
import getPackagesRequest from './getPackages.repository';

type InitialState = {
  loading: boolean;
  data?: PackageDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getPackages = createAsyncThunk(
  'getPackages',
  async (params: { groupId?: number }) => {
    return await getPackagesRequest(params.groupId);
  }
);

const getPackagesSlice = createSlice({
  name: 'getPackages',
  initialState,
  reducers: {
    getPackagesReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPackages.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getPackages.fulfilled,
      (state, action: PayloadAction<PackageDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getPackages.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getPackagesSlice.reducer;
export const { getPackagesReset } = getPackagesSlice.actions;
