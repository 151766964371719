import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { StatDto } from '../../../models/dtos/stat.dto';
import { Errors } from '../../errors';
import getStatRequest from './getStat.repository';

type InitialState = {
  loading: boolean;
  data?: StatDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getStat = createAsyncThunk('getStat', async () => {
  return await getStatRequest();
});

const getStatSlice = createSlice({
  name: 'getStat',
  initialState,
  reducers: {
    getStatReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStat.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getStat.fulfilled,
      (state, action: PayloadAction<StatDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getStat.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getStatSlice.reducer;
export const { getStatReset } = getStatSlice.actions;
