const LogoutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60039 1.19922C2.93764 1.19922 2.40039 1.73648 2.40039 2.39922V15.5992C2.40039 16.262 2.93765 16.7992 3.60039 16.7992H12.6004C12.9317 16.7992 13.2004 16.5305 13.2004 16.1992C13.2004 15.8679 12.9317 15.5992 12.6004 15.5992H3.60039V2.39922H12.6004C12.9317 2.39922 13.2004 2.13059 13.2004 1.79922C13.2004 1.46785 12.9317 1.19922 12.6004 1.19922H3.60039ZM15.1247 5.87496C14.8904 5.64064 14.5104 5.64064 14.2761 5.87496C14.0418 6.10927 14.0418 6.48917 14.2761 6.72348L15.9519 8.39922H7.80039C7.46902 8.39922 7.20039 8.66785 7.20039 8.99922C7.20039 9.33059 7.46902 9.59922 7.80039 9.59922H15.9519L14.2761 11.275C14.0418 11.5093 14.0418 11.8892 14.2761 12.1235C14.5104 12.3578 14.8904 12.3578 15.1247 12.1235L17.8247 9.42348C18.059 9.18917 18.059 8.80927 17.8247 8.57496L15.1247 5.87496Z"
        fill="#FF8589"
      />
    </svg>
  );
};

export default LogoutIcon;
