import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoinDto } from '../../../models/dtos/coin.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getCoinsRequest from './getCoins.repository';

type InitialState = {
  loading: boolean;
  data?: CoinDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getCoins = createAsyncThunk('getCoins', async () => {
  return await getCoinsRequest();
});

const getCoinsSlice = createSlice({
  name: 'getCoins',
  initialState,
  reducers: {
    getCoinsReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoins.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getCoins.fulfilled,
      (state, action: PayloadAction<CoinDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getCoins.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getCoinsSlice.reducer;
export const { getCoinsReset } = getCoinsSlice.actions;
