import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { LeaderboardDto } from '../../../models/dtos/leaderboard.dto';
import { Errors } from '../../errors';
import getLeaderboardLastMonthRequest from './getLeaderboardLastMonth.repository';

type InitialState = {
  loading: boolean;
  data?: LeaderboardDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getLeaderboardLastMonth = createAsyncThunk(
  'getLeaderboardLastMonth',
  async (teamId?: number) => {
    return await getLeaderboardLastMonthRequest(teamId);
  }
);

const getLeaderboardLastMonthSlice = createSlice({
  name: 'getLeaderboardLastMonth',
  initialState,
  reducers: {
    getLeaderboardLastMonthReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaderboardLastMonth.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getLeaderboardLastMonth.fulfilled,
      (state, action: PayloadAction<LeaderboardDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getLeaderboardLastMonth.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getLeaderboardLastMonthSlice.reducer;
export const { getLeaderboardLastMonthReset } =
  getLeaderboardLastMonthSlice.actions;
