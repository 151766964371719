import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import finishQuizRequest from './finishQuiz.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const finishQuiz = createAsyncThunk('finishQuiz', async () => {
  return await finishQuizRequest();
});

const finishQuizSlice = createSlice({
  name: 'finishQuiz',
  initialState,
  reducers: {
    finishQuizReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(finishQuiz.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      finishQuiz.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(finishQuiz.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default finishQuizSlice.reducer;
export const { finishQuizReset } = finishQuizSlice.actions;
