const BenefitsIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37445 1.50274L8.34935 1.56307L6.70309 5.52115C6.65125 5.6458 6.53402 5.73096 6.39947 5.74175L2.12639 6.08432L2.06124 6.08954L1.66339 6.12144L1.30244 6.15038C0.983248 6.17597 0.853819 6.57431 1.09701 6.78263L1.37202 7.0182L1.67514 7.27786L1.72477 7.32037L4.98041 10.1092C5.08292 10.197 5.12771 10.3348 5.09637 10.4661L4.10173 14.6359L4.08656 14.6995L3.99396 15.0877L3.90993 15.4399C3.83564 15.7514 4.17449 15.9976 4.44776 15.8307L4.75679 15.642L5.09739 15.4339L5.15317 15.3998L8.81153 13.1653C8.92673 13.095 9.07163 13.095 9.18683 13.1653L12.8451 15.3998L12.9009 15.4339L13.2416 15.642L13.5506 15.8307C13.8239 15.9976 14.1627 15.7514 14.0885 15.4399L14.0043 15.0877L13.9118 14.6995L13.8966 14.6359L12.902 10.4661C12.8707 10.3348 12.9155 10.197 13.0179 10.1092L16.2735 7.32037L16.3232 7.27786L16.6263 7.0182L16.9014 6.78263C17.1445 6.57431 17.0151 6.17597 16.6959 6.15038L16.335 6.12144L15.9371 6.08954L15.872 6.08432L11.5989 5.74175C11.4643 5.73096 11.3471 5.6458 11.2953 5.52115L9.649 1.56307L9.62391 1.50274L9.47064 1.13421L9.33157 0.799874C9.20859 0.504209 8.78976 0.504209 8.66678 0.799874L8.52773 1.13421L8.37445 1.50274ZM8.99918 3.12545L7.81107 5.98199C7.58642 6.52212 7.07847 6.89117 6.49536 6.93792L3.41148 7.18514L5.76107 9.19782C6.20534 9.57839 6.39936 10.1755 6.26363 10.7445L5.5458 13.7539L8.18603 12.1412C8.68525 11.8363 9.31311 11.8363 9.81234 12.1412L12.4525 13.7539L11.7347 10.7445C11.599 10.1755 11.793 9.57839 12.2372 9.19782L14.5868 7.18514L11.503 6.93792C10.9199 6.89117 10.4119 6.52212 10.1873 5.98199L8.99918 3.12545Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BenefitsIcon;
