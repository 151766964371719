import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { ComponentType, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import AnnouncementIcon from '../../../assets/images/menu/AnnouncementIcon';
import BenefitsIcon from '../../../assets/images/menu/BenefitsIcon';
import HomeIcon from '../../../assets/images/menu/HomeIcon';
import PointboardIcon from '../../../assets/images/menu/PointboardIcon';
import { ReactComponent as PointIcon } from '../../../assets/images/menu/point-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/menu/profile-icon.svg';
import { ReactComponent as QrIcon } from '../../../assets/images/menu/qr-icon.svg';
import { ReactComponent as SubscribeIcon } from '../../../assets/images/menu/subscribe-icon.svg';
import { ReactComponent as TeamIcon } from '../../../assets/images/menu/team-icon.svg';
import plus from '../../../assets/images/menu/white-plus.svg';
import FirebaseService from '../../../core/services/firebase/firebaseService';
import FirebaseKey from '../../FirebaseKey/FirebaseKey';
import { MenuType } from '../AppMenu';

interface IProps {
  className?: string;
  selectedMenu?: MenuType;
  menuType: MenuType;
  link?: string;
  handleClickMenu: (type: MenuType) => void;
}

function LeftMenuItem(props: IProps) {
  const navigate = useNavigate();

  const handleClickMenu = () => {
    if (props.link) {
      navigate(props.link);
    }
    props.handleClickMenu(props.menuType);
  };

  function getIcon(): ComponentType {
    switch (props.menuType) {
      case MenuType.qr:
        return QrIcon;
      case MenuType.home:
        return HomeIcon;
      case MenuType.team:
        return TeamIcon;
      case MenuType.point:
        return PointIcon;
      case MenuType.pointBoard:
        return PointboardIcon;
      case MenuType.subscribe:
        return SubscribeIcon;
      case MenuType.announcements:
        return AnnouncementIcon;
      case MenuType.benefits:
        return BenefitsIcon;
      default:
        return ProfileIcon;
    }
  }

  function getText(): ReactNode {
    let text = FirebaseService.hamburger_profil_buton;
    switch (props.menuType) {
      case MenuType.qr:
        text = FirebaseService.hamburger_qr_buton;
        break;
      case MenuType.home:
        text = FirebaseService.hamburger_anasayfa_buton;
        break;
      case MenuType.team:
        text = FirebaseService.hamburger_takimlar;
        break;
      case MenuType.point:
        return (
          <div className="inline-flex items-center gap-1">
            <img src={plus} alt="plus" />
            <FirebaseKey firebaseKey={FirebaseService.hamburger_plus_puan} />
          </div>
        );
      case MenuType.pointBoard:
        text = FirebaseService.hamburger_plus_lig;
        break;
      case MenuType.subscribe:
        text = FirebaseService.hamburger_abonelik;
        break;
      case MenuType.announcements:
        text = FirebaseService.hamburger_duyurular;
        break;
      case MenuType.benefits:
        text = FirebaseService.hamburger_plus_faydalar;
        break;
    }

    return <FirebaseKey firebaseKey={text} />;
  }

  function qrClassNames(): string | undefined {
    if (props.menuType === MenuType.qr) {
      return twMerge(
        `bg-menuQrBg bg-no-repeat bg-right bg-cover`,
        props.selectedMenu === props.menuType
          ? 'border-green'
          : 'border-white/5'
      );
    }

    if (props.selectedMenu === props.menuType) {
      return 'bg-[#0091F714] border-none font-bold';
    }

    return 'bg-transparent border-none text-white/70 font-bold';
  }

  function selectedClassNames(): string | undefined {
    if (
      props.selectedMenu === props.menuType &&
      props.menuType !== MenuType.qr
    ) {
      return 'bg-[#0091F735] border border-solid border-[#0091F788] text-[#0091F7]';
    }
  }

  return (
    <Button
      block
      className={twMerge(
        `h-10 md:h-12 flex items-center rounded-xl text-white m-0 w-full p-1 text-left overflow-hidden`,
        qrClassNames()
      )}
      icon={
        <Icon
          className={twMerge(
            `text-base md:text-lg rounded-lg p-[7px] md:p-2.5`,
            selectedClassNames()
          )}
          component={getIcon()}
        />
      }
      onClick={handleClickMenu}
    >
      {getText()}
    </Button>
  );
}

export default LeftMenuItem;
