import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnouncementDto } from '../../../models/dtos/announcement.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getAnnouncementsRequest from './getAnnouncements.repository';

type InitialState = {
  loading: boolean;
  data?: AnnouncementDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getAnnouncements = createAsyncThunk(
  'getAnnouncements',
  async (teamId?: number) => {
    return await getAnnouncementsRequest(teamId);
    // return [
    //   {
    //     id: 16,
    //     teamId: 1,
    //     title: 'Omuz Omuza Taraftarlar Buluşması',
    //     description:
    //       'Figma ipsum component variant main layer. Ellipse arrow thumbnail library frame. Frame hand undo polygon subtract variant figma horizontal style component. Strikethrough bold follower text object vector edit rectangle hand layer.',
    //     imageUrl:
    //       'https://static.4play.com.tr/taraftarplus/duyurular/sample.png',
    //     announceDate: '2024-01-28',
    //     announceFrom: '14:00:00',
    //     announceTo: '15:00:00',
    //     type: AnnouncementType.EVENT,
    //   },
    //   {
    //     id: 17,
    //     teamId: 1,
    //     title: 'Omuz Omuza Taraftarlar Buluşması',
    //     description:
    //       'Figma ipsum component variant main layer. Ellipse arrow thumbnail library frame. Frame hand undo polygon subtract variant figma horizontal style component. Strikethrough bold follower text object vector edit rectangle hand layer.',
    //     imageUrl:
    //       'https://static.4play.com.tr/taraftarplus/duyurular/sample.png',
    //     announceDate: '2024-01-28',
    //     announceFrom: '14:00:00',
    //     announceTo: '15:00:00',
    //     type: AnnouncementType.EVENT,
    //   },
    // ];
  }
);

const getAnnouncementsSlice = createSlice({
  name: 'getAnnouncements',
  initialState,
  reducers: {
    getAnnouncementsReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnnouncements.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getAnnouncements.fulfilled,
      (state, action: PayloadAction<AnnouncementDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getAnnouncements.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getAnnouncementsSlice.reducer;
export const { getAnnouncementsReset } = getAnnouncementsSlice.actions;
