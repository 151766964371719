import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { MobilePaymentCheckDto } from '../../../models/dtos/mobilePaymentCheck.dto';
import { Errors } from '../../errors';
import mobilePaymentCheckRequest from './mobilePaymentCheck.repository';

type InitialState = {
  loading: boolean;
  data?: MobilePaymentCheckDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const mobilePaymentCheck = createAsyncThunk(
  'mobilePaymentCheck',
  async (smsCid: string, { dispatch }) => {
    return await mobilePaymentCheckRequest(smsCid);
  }
);

const mobilePaymentCheckSlice = createSlice({
  name: 'mobilePaymentCheck',
  initialState,
  reducers: {
    mobilePaymentCheckReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mobilePaymentCheck.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      mobilePaymentCheck.fulfilled,
      (state, action: PayloadAction<MobilePaymentCheckDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(mobilePaymentCheck.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default mobilePaymentCheckSlice.reducer;
export const { mobilePaymentCheckReset } = mobilePaymentCheckSlice.actions;
