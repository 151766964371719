import { LeaderboardDto } from '../../../models/dtos/leaderboard.dto';
import axios from '../../axios';

export default async function getLeaderboardRequest(
  teamId?: number
): Promise<LeaderboardDto[]> {
  const response = await axios.get<LeaderboardDto[]>(
    `${process.env.REACT_APP_BASE_URL}/leaderboard/month`,
    {
      params: {
        teamId,
      },
    }
  );
  return response.data;
}
