import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import sendTeamsRequest from './sendTeams.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const sendTeams = createAsyncThunk(
  'sendTeams',
  async (teamId: number) => {
    return await sendTeamsRequest(teamId);
  }
);

const sendTeamsSlice = createSlice({
  name: 'sendTeams',
  initialState,
  reducers: {
    sendTeamsReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendTeams.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      sendTeams.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(sendTeams.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default sendTeamsSlice.reducer;
export const { sendTeamsReset } = sendTeamsSlice.actions;
