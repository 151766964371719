import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { TriviaQuestionDto } from '../../../models/dtos/triviaQuestion.dto';
import { Errors } from '../../errors';
import getTriviaQuestionRequest from './getTriviaQuestion.repository';

type InitialState = {
  loading: boolean;
  data?: TriviaQuestionDto;
  error?: ErrorDto;
  lastId?: number;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
  lastId: undefined,
};

export const getTriviaQuestion = createAsyncThunk(
  'getTriviaQuestion',
  async ({ teamId, lastId }: { teamId?: number; lastId?: number }) => {
    return await getTriviaQuestionRequest({ teamId, lastId });
  }
);

const getTriviaQuestionSlice = createSlice({
  name: 'getTriviaQuestion',
  initialState,
  reducers: {
    getTriviaQuestionReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
    setTriviaLastId: (state, action) => {
      state.lastId = action.payload.lastId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTriviaQuestion.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getTriviaQuestion.fulfilled,
      (state, action: PayloadAction<TriviaQuestionDto>) => {
        state.loading = false;
        state.data = action.payload;
        state.lastId = action.payload.id;
      }
    );
    builder.addCase(getTriviaQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getTriviaQuestionSlice.reducer;
export const { getTriviaQuestionReset } = getTriviaQuestionSlice.actions;
