import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { TribuneDto } from '../../../models/dtos/tribune.dto';
import { Errors } from '../../errors';
import getTribuneRequest from './getTribune.repository';

type InitialState = {
  loading: boolean;
  data?: TribuneDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getTribune = createAsyncThunk(
  'getTribune',
  async (teamId: number) => {
    return await getTribuneRequest(teamId);
  }
);

const getTribuneSlice = createSlice({
  name: 'getTribune',
  initialState,
  reducers: {
    getTribuneReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTribune.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getTribune.fulfilled,
      (state, action: PayloadAction<TribuneDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getTribune.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getTribuneSlice.reducer;
export const { getTribuneReset } = getTribuneSlice.actions;
