import axios from '../../axios';

export default async function sendNicknameRequest(
  nickname: string
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/users/nickname`,
    {
      nickname
    }
  );
  return response.data;
}
