import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import Helpers from '../../../core/utilities/helpers';
import { LocalStorage } from '../../../core/utilities/storage';
import AppMenu from '../../AppMenu/AppMenu';
import CookieAgreement from '../../CookieAgreement/CookieAgreement';
import './AppLayout.scss';

function AppLayout() {
  const location = useLocation();
  const token = Helpers.getQueryParam(location, LocalStorage.token);

  return (
    <Layout className="min-h-screen lg:min-h-[max(890px,100vh)] lg:max-h-screen h-full bg-[#181818] md:p-5 md:gap-5">
      {!token && <AppMenu />}
      <Layout.Content className="flex flex-col">
        <Outlet />
      </Layout.Content>
      <CookieAgreement />
    </Layout>
  );
}

export default AppLayout;
