import { TribuneDto } from '../../../models/dtos/tribune.dto';
import axios from '../../axios';

export default async function getTribuneGroupRequest(
  teamId: number
): Promise<TribuneDto[]> {
  const response = await axios.get<TribuneDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/tribune-group`,
    {
      params: {
        teamId,
      },
    }
  );
  return response.data;
}
