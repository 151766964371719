import { UserInfoRequest } from '../../../models/requests/userInfo.request';
import axios from '../../axios';

export default async function updateUserInfoRequest(
  request: UserInfoRequest
): Promise<void> {
  const response = await axios.put<void>(
    `${process.env.REACT_APP_BASE_URL}/users`,
    request
  );
  return response.data;
}
