const HomeIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49477 0.265312C8.77496 -0.0103123 9.22442 -0.0103123 9.50461 0.265312L17.6046 8.23333C17.8881 8.51219 17.8918 8.96804 17.613 9.25152C17.3341 9.53501 16.8783 9.53875 16.5948 9.25988L15.5997 8.28102V14.9986C15.5997 15.3299 15.3311 15.5986 14.9997 15.5986H2.99968C2.66831 15.5986 2.39968 15.3299 2.39968 14.9986V8.28102L1.40461 9.25988C1.12113 9.53875 0.665265 9.53501 0.386406 9.25152C0.107548 8.96804 0.111292 8.51219 0.39477 8.23333L8.49477 0.265312ZM8.99968 1.78856L14.3997 7.10058V14.3986H11.9997V10.1986C11.9997 9.86723 11.7311 9.5986 11.3997 9.5986H7.79968C7.46831 9.5986 7.19968 9.86723 7.19968 10.1986V14.3986H3.59968V7.10058L8.99968 1.78856ZM8.39968 14.3986H10.7997V10.7986H8.39968V14.3986Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomeIcon;
