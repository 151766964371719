import axios from '../../axios';

export default async function cancelSubRequest(
  userSubId: number
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/payment/cancel-sub`,
    undefined,
    {
      params: {
        userSubId,
      },
    }
  );
  return response.data;
}
