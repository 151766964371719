import { TeamDto } from '../../../models/dtos/team.dto';
import axios from '../../axios';

export default async function getTeamsRequest(
  name: string
): Promise<TeamDto[]> {
  const response = await axios.get<TeamDto[]>(
    `${process.env.REACT_APP_BASE_URL}/countries/${name}/teams`
  );
  return response.data;
}
