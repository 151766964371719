import { Link } from 'react-router-dom';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import x from '../../assets/svg/x.svg';
import FirebaseService from '../../core/services/firebase/firebaseService';
import Constants from '../../core/utilities/constants';
import { router } from '../../core/utilities/router';
import FirebaseKey from '../FirebaseKey/FirebaseKey';

function Footer() {
  return (
    <div className="px-4 md:px-8 md:bg-none bg-footerBg bg-cover bg-repeat bg-center">
      <div className="max-w-5xl mx-auto md:border-solid border-0 border-t-2 border-gray-light pt-8 pb-6">
        <div className="flex justify-between md:items-start">
          <div className="flex flex-col justify-start">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col md:flex-row md:gap-10 mb-4 pl-0 items-start md:items-center">
                <Link
                  to={router.LANDING}
                  className="text-black no-underline mb-2 md:mb-0"
                >
                  <FirebaseKey
                    firebaseKey={FirebaseService.footer_homepage_tab}
                  />
                </Link>
                <Link
                  to={router.CONTACTUS}
                  className="text-black no-underline mb-2 md:mb-0"
                >
                  <FirebaseKey
                    firebaseKey={FirebaseService.footer_contact_tab}
                  />
                </Link>
                <Link
                  to={router.FAQ}
                  className="text-black no-underline mb-2 md:mb-0"
                >
                  <FirebaseKey firebaseKey={FirebaseService.footer_faq_tab} />
                </Link>
              </div>
              <div className="text-xs text-gray-dark hidden md:block">
                © 2024, Taraftar Plus.
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end gap-3 ">
            {/* <div className="flex flex-col md:flex-row gap-4">
              <a
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="w-24 md:w-32" src={appStore} alt="app" />
              </a>
              <a
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="w-24 md:w-32" src={playStore} alt="google" />
              </a>
            </div> */}
            <div className="flex gap-4 md:justify-end ">
              <a
                href={Constants.socialMediaInstagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-5 md:w-6 hidden md:block"
                  src={instagram}
                  alt="instagram"
                />
              </a>
              <a
                href={Constants.socialMediaX}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="w-5 md:w-6 hidden md:block" src={x} alt="x" />
              </a>
              <a
                href={Constants.socialMediaFacebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-5 md:w-6 hidden md:block"
                  src={facebook}
                  alt="facebook"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-6 md:hidden">
          <a
            href={Constants.socialMediaInstagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-5 md:w-6" src={instagram} alt="instagram" />
          </a>
          <a
            href={Constants.socialMediaX}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-5 md:w-6 " src={x} alt="x" />
          </a>
          <a
            href={Constants.socialMediaFacebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-5 md:w-6" src={facebook} alt="facebook" />
          </a>
        </div>
        <div className="text-xs text-gray-dark text-center md:hidden">
          © 2024, Taraftar Plus.
        </div>
      </div>
    </div>
  );
}

export default Footer;
