import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { OtpDto } from '../../../models/dtos/otp.dto';
import { OtpRequest } from '../../../models/requests/otp.request';
import { LocalStorage } from '../../../utilities/storage';
import { Errors } from '../../errors';
import otpRequest from './otp.repository';

type InitialState = {
  loading: boolean;
  data?: OtpDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const otp = createAsyncThunk(
  'otp',
  async (request: OtpRequest, { dispatch }) => {
    const response = await otpRequest(request);
    LocalStorage.set(LocalStorage.token, response.token);
    return response;
  }
);

const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    otpReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(otp.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(otp.fulfilled, (state, action: PayloadAction<OtpDto>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(otp.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default otpSlice.reducer;
export const { otpReset } = otpSlice.actions;
