import { CurrentMatchDto } from '../../../models/dtos/currentMatch.dto';
import axios from '../../axios';

export async function getCurrentMatchesRequest(
  leagueId: number
): Promise<CurrentMatchDto[]> {
  const response = await axios.get<CurrentMatchDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/matches/current`,
    {
      params: {
        leagueId,
      },
    }
  );
  return response.data;
}
