import { CommentListDto } from '../../../models/dtos/commentList.dto';
import axios from '../../axios';

export default async function getCommentsRequest(
  pageNumber: number,
  teamId?: number
): Promise<CommentListDto> {
  const response = await axios.get<CommentListDto>(
    `${process.env.REACT_APP_BASE_URL}/users/comment`,
    {
      params: {
        pageNumber,
        teamId,
      },
    }
  );
  return response.data;
}
