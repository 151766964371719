import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { QuizRequest } from '../../../models/requests/quiz.request';
import { Errors } from '../../errors';
import answerQuizRequest from './answerQuiz.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const answerQuiz = createAsyncThunk(
  'answerQuiz',
  async (request: QuizRequest) => {
    return await answerQuizRequest(request);
  }
);

const answerQuizSlice = createSlice({
  name: 'answerQuiz',
  initialState,
  reducers: {
    answerQuizReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(answerQuiz.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      answerQuiz.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(answerQuiz.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default answerQuizSlice.reducer;
export const { answerQuizReset } = answerQuizSlice.actions;
