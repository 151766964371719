import { QuizRequest } from '../../../models/requests/quiz.request';
import axios from '../../axios';

export default async function answerQuizRequest(
  request: QuizRequest
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/quiz/active`,
    request
  );
  return response.data;
}
