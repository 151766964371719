import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentMatchDto } from '../../../models/dtos/currentMatch.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import { getCurrentMatchesRequest } from './getCurrentMatches.repository';

type InitialState = {
  loading: boolean;
  data?: CurrentMatchDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getCurrentMatches = createAsyncThunk(
  'getCurrentMatches',
  async (leagueId: number) => {
    return await getCurrentMatchesRequest(leagueId);
  }
);

const getCurrentMatchesSlice = createSlice({
  name: 'getCurrentMatches',
  initialState,
  reducers: {
    getCurrentMatchesReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentMatches.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getCurrentMatches.fulfilled,
      (state, action: PayloadAction<CurrentMatchDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getCurrentMatches.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getCurrentMatchesSlice.reducer;
export const { getCurrentMatchesReset } = getCurrentMatchesSlice.actions;
