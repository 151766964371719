import { ConfigProvider } from 'antd';
import trTR from 'antd/locale/tr_TR';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './core/services/store';
import { UI } from './core/utilities/ui';
import reportWebVitals from './reportWebVitals';
import { ParallaxProvider } from 'react-scroll-parallax';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('tr');
dayjs.extend(customParseFormat);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderEmpty = () => <div></div>;

root.render(
  <ConfigProvider locale={trTR} theme={UI.getTheme()} renderEmpty={renderEmpty}>
    <ParallaxProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ParallaxProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
