import { PaymentDto } from '../../../models/dtos/payment.dto';
import { PaymentRequest } from '../../../models/requests/payment.request';
import axios from '../../axios';

export default async function paymentStartRequest(
  request: PaymentRequest
): Promise<PaymentDto> {
  const response = await axios.post<PaymentDto>(
    `${process.env.REACT_APP_BASE_URL}/payment/start`,
    request
  );
  return response.data;
}
