export default class Constants {
  public static readonly urlReferalCode: string = 'refferalCode';

  public static readonly defaultAvatar: string =
    'https://static.4play.com.tr/taraftarplus/default.png';

  public static readonly bannedSeparator: string = '-';

  public static readonly socialMediaFacebook: string =
    'https://www.facebook.com/profile.php?id=61553011049383';
  public static readonly socialMediaInstagram: string =
    'https://www.instagram.com/taraftarpluscom/?igsh=dXN6NWQ3NnJhdTU5';
  public static readonly socialMediaX: string =
    'https://x.com/taraftarplus?s=21';

  public static readonly countryTr: string = 'Türkiye';

  public static readonly etkLink: string =
    'https://static.4play.com.tr/taraftarplus/agreements/etk.pdf';
  public static readonly acikRizaLink: string =
    'https://static.4play.com.tr/taraftarplus/agreements/acik-riza.pdf';
  public static readonly userAgreementLink: string =
    'https://static.4play.com.tr/taraftarplus/agreements/kullanici-sozlesmesi.pdf';
  public static readonly subsAgreementLink: string =
    'https://static.4play.com.tr/taraftarplus/agreements/abonelik-sozlesmesi.pdf';
  public static readonly kvkkLink: string =
    'https://static.4play.com.tr/taraftarplus/agreements/kvkk.pdf';
  public static readonly cookiePolicy: string =
    'https://static.4play.com.tr/taraftarplus/agreements/TaraftarPlus_Çerez_Politikası.pdf';

  public static readonly wheelFinishedEvent: string = 'wheelFinished';
  public static readonly goHomeEvent: string = 'goHome';

  public static readonly kgbLink: string =
    'https://jdmt.adj.st/?adj_t=19q81tph';
}
