import { CityDto } from '../../../models/dtos/city.dto';
import axios from '../../axios';

export default async function getUserCitiesRequest(
  country: string
): Promise<CityDto[]> {
  const response = await axios.get<CityDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/cities`,
    {
      params: {
        country,
      },
    }
  );
  return response.data;
}
