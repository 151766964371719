import axios from '../../axios';

export default async function sendInviteRequest(
  toMsisdn: string
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/users/invite`,
    undefined,
    {
      params: {
        toMsisdn,
      },
    }
  );
  return response.data;
}
