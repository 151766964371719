import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveWheelDto } from '../../../models/dtos/activeWheel.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getActiveWheelRequest from './getActiveWheel.repository';

type InitialState = {
  loading: boolean;
  data?: ActiveWheelDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getActiveWheel = createAsyncThunk('getActiveWheel', async () => {
  return await getActiveWheelRequest();
});

const getActiveWheelSlice = createSlice({
  name: 'getActiveWheel',
  initialState,
  reducers: {
    getActiveWheelReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveWheel.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getActiveWheel.fulfilled,
      (state, action: PayloadAction<ActiveWheelDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getActiveWheel.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getActiveWheelSlice.reducer;
export const { getActiveWheelReset } = getActiveWheelSlice.actions;
