import { PackageDto } from '../../../models/dtos/package.dto';
import axios from '../../axios';

export default async function getPackagesRequest(
  groupId?: number
): Promise<PackageDto[]> {
  const response = await axios.get<PackageDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/subscription/all`,
    {
      params: {
        groupId,
      },
    }
  );
  return response.data;
}
