import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { setMe } from '../../app/setMe/setMe.slice';
import { Errors } from '../../errors';
import deleteUserRequest from './deleteUser.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (_, { dispatch }) => {
    await deleteUserRequest();
    dispatch(setMe());
    return;
  }
);

const deleteUserSlice = createSlice({
  name: 'deleteUser',
  initialState,
  reducers: {
    deleteUserReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      deleteUser.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default deleteUserSlice.reducer;
export const { deleteUserReset } = deleteUserSlice.actions;
