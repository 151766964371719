export class LocalStorage {
  public static readonly token: string = 'token';
  public static readonly cookieAgreement: string = 'cookieAgreement';

  public static get(key: string) {
    return localStorage.getItem(key);
  }

  public static set(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  public static remove(key: string) {
    return localStorage.removeItem(key);
  }
}

export class SessionStorage {
  public static readonly referalCode: string = 'referalCode';

  public static get(key: string) {
    return sessionStorage.getItem(key) || undefined;
  }

  public static set(key: string, value: string) {
    return sessionStorage.setItem(key, value);
  }

  public static remove(key: string) {
    return sessionStorage.removeItem(key);
  }
}
