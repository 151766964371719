import axios from '../../axios';

export default async function nextQuizRequest(): Promise<number> {
  try {
    const response = await axios.get<number>(
      `${process.env.REACT_APP_BASE_URL}/quiz/next`
    );
    return response.data;
  } catch (error: any) {
    if (error && 'response' in error && 'data' in error.response) {
      throw error.response.data;
    }
    throw error;
  }
}
