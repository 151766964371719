import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { PaymentDto } from '../../../models/dtos/payment.dto';
import { PaymentRequest } from '../../../models/requests/payment.request';
import { Errors } from '../../errors';
import paymentStartRequest from './paymentStart.repository';

type InitialState = {
  loading: boolean;
  data?: PaymentDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const paymentStart = createAsyncThunk(
  'paymentStart',
  async (request: PaymentRequest, { dispatch }) => {
    return await paymentStartRequest(request);
  }
);

const paymentStartSlice = createSlice({
  name: 'paymentStart',
  initialState,
  reducers: {
    paymentStartReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(paymentStart.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      paymentStart.fulfilled,
      (state, action: PayloadAction<PaymentDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(paymentStart.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default paymentStartSlice.reducer;
export const { paymentStartReset } = paymentStartSlice.actions;
