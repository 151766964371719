import Icon from '@ant-design/icons';
import { Button, Col, Layout, Progress, Row } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import logoutIcon from '../../assets/images/important-icon.png';
import logo from '../../assets/images/logo_white.png';
import tick from '../../assets/images/menu/blue-tick-icon.svg';
import game from '../../assets/images/menu/game2.png';
import { ReactComponent as MenuIcon } from '../../assets/images/menu/hamburger-menu-icon-2.svg';
import poll from '../../assets/images/menu/poll2.png';
// import share from '../../assets/images/menu/share-icon.png';
import groupIcon from '../../assets/images/group-icon.svg';
import hamburgerMenuProfileBg from '../../assets/images/hamburgerMenuProfileBg.png';
import subscriptionCard from '../../assets/images/home/subscription-card.png';
import HelpIcon from '../../assets/images/menu/HelpIcon';
import LogoutIcon from '../../assets/images/menu/LogoutIcon';
import MailIcon from '../../assets/images/menu/MailIcon';
import ShareIcon from '../../assets/images/menu/ShareIcon';
import TeamIcon from '../../assets/images/menu/TeamIcon';
import wheel from '../../assets/images/menu/wheel2.png';
import mobileLogo from '../../assets/images/mobile-logo.png';
import settingsIcon from '../../assets/images/settings.svg';
import PointIcon from '../../assets/svg/PointIcon';
import useScreenSize from '../../core/hooks/useScreenSize';
import { PackageDto } from '../../core/models/dtos/package.dto';
import { setMe } from '../../core/services/app/setMe/setMe.slice';
import { Errors } from '../../core/services/errors';
import FirebaseService from '../../core/services/firebase/firebaseService';
import { useAppDispatch, useAppSelector } from '../../core/services/hooks';
import { getActiveQuiz } from '../../core/services/quiz/getActiveQuiz/getActiveQuiz.slice';
import Constants from '../../core/utilities/constants';
import Helpers from '../../core/utilities/helpers';
import { router } from '../../core/utilities/router';
import FirebaseKey from '../FirebaseKey/FirebaseKey';
import AreYouSureModal from '../Modals/AreYouSureModal/AreYouSureModal';
import './AppMenu.scss';
import LeftMenuItem from './LeftMenuItem/LeftMenuItem';
export enum MenuType {
  game,
  wheel,
  profile,
  poll,
  qr,
  home,
  team,
  point,
  pointBoard,
  subscribe,
  announcements,
  benefits,
}

interface IProps {}

function AppMenu(props: IProps) {
  const me = useAppSelector((state) => state.app.setMe.me);
  const pathname = useAppSelector((state) => state.app.setPathname.pathname);
  const activeQuizState = useAppSelector((state) => state.quiz.getActiveQuiz);

  const windowSize = useScreenSize();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState<MenuType>();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  // const [isShareTooltipOpen, setIsShareTooltipOpen] = useState(false);
  // const [copyText, setCopyText] = useState('Kopyala');

  const [isMobileMenuVisible, setIsMobileMenuVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (isMobileMenuVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isMobileMenuVisible]);

  useEffect(() => {
    dispatch(getActiveQuiz());
  }, [dispatch]);

  useEffect(() => {
    switch (pathname) {
      case router.PROFILE:
        setSelectedMenu(MenuType.profile);
        break;
      case router.HOME:
        setSelectedMenu(MenuType.home);
        break;
      case router.SUBSCRIPTION:
        setSelectedMenu(MenuType.subscribe);
        break;
      case router.LEADERBOARD:
        setSelectedMenu(MenuType.pointBoard);
        break;
      case router.ANNOUNCEMENTS:
        setSelectedMenu(MenuType.announcements);
        break;
      case router.TRIVIA:
        setSelectedMenu(MenuType.game);
        break;
      case router.WHEEL:
        setSelectedMenu(MenuType.wheel);
        break;
      case router.PLUSPOINT:
        setSelectedMenu(MenuType.point);
        break;
      case router.BENEFITS:
        setSelectedMenu(MenuType.benefits);
        break;
      default:
        setSelectedMenu(undefined);
        break;
    }
    setIsMobileMenuVisible(false);
  }, [pathname]);

  const handleClickMenu = (type: MenuType) => {
    setSelectedMenu(type);
    setIsMobileMenuVisible(false);
  };

  // const onCopy = (): void => {
  //   setIsShareTooltipOpen(true);
  //   setCopyText('Kopyalandı');
  //   setTimeout(() => {
  //     setCopyText('Kopyala');
  //     setIsShareTooltipOpen(false);
  //   }, 2000);
  // };

  const handleLogoutButton = () => {
    setIsLogoutModalVisible(true);
  };

  const handleLogout = () => {
    dispatch(setMe());
    navigate(router.LANDING);
  };

  function menuClassNames(type: MenuType): string {
    return twMerge(
      `p-2 rounded-md break-words text-center whitespace-normal h-full`,
      type === selectedMenu ? 'border-green' : ''
    );
  }

  function renderMenuContent(): ReactNode {
    if (me) {
      const { avatarImageUrl, activeSubs, points, team, tribuneGroup } = me;

      const isSubscribe = activeSubs && activeSubs.length > 0;

      const renderSubscriptionInfo = (): ReactNode => {
        return (
          <div className="space-y-1">
            <div className="flex items-center gap-1">
              <TeamIcon />
              <span>{team?.name}</span>
            </div>
            {tribuneGroup ? (
              <div className="flex items-center gap-1">
                <img src={groupIcon} className="h-4 w-4 p-0.5" alt="" />
                {tribuneGroup}
              </div>
            ) : (
              <Link
                to={'/profile#edit-profile'}
                className="flex items-center gap-1"
                onClick={(e) => {
                  //do not trigger parent's onClick
                  e.stopPropagation();
                }}
              >
                <img src={groupIcon} className="h-4 w-4 p-0.5" alt="" />
                <FirebaseKey firebaseKey={FirebaseService.menu_choise_group} />
              </Link>
            )}
          </div>
        );
      };

      return (
        <div className="flex flex-grow flex-col gap-6 text-white">
          <div className="flex flex-grow flex-col gap-3">
            <div
              className="relative mt-7 flex cursor-pointer items-end gap-3 rounded-2xl"
              onClick={() => {
                navigate(router.PROFILE);
                setIsMobileMenuVisible(false);
              }}
            >
              <img src={hamburgerMenuProfileBg} alt="" className="absolute" />
              <img
                className="-mt-7 w-20"
                src={avatarImageUrl ? avatarImageUrl : Constants.defaultAvatar}
                alt="user"
              />
              <div className="flex w-full justify-between gap-1 p-0">
                <div className="flex flex-col items-start justify-evenly">
                  <div className="mb-2 flex items-center gap-1 text-base">
                    {isSubscribe && <img src={tick} alt="tick" />}
                    <span className="max-w-[12ch] overflow-x-clip overflow-ellipsis">
                      {Helpers.getUserName(me, true)}
                    </span>
                  </div>
                  {renderSubscriptionInfo()}
                </div>
                <div className="ml-auto flex flex-col items-center gap-1">
                  <div>
                    <Progress
                      type="circle"
                      className="text-white [&_.ant-progress-circle-path]:stroke-[#0DC8FF88] [&_span]:text-white"
                      size={45}
                      percent={me?.progress || 0}
                    />
                  </div>
                  <Link to="/profile">
                    <img
                      className="-m-2 h-12 w-12"
                      src={settingsIcon}
                      alt="point"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="mb-4 flex cursor-pointer items-center gap-1 rounded-lg border border-solid border-primary/30 bg-black-dark/50 py-1 pl-1 pr-1 text-primary"
              onClick={() => {
                navigate(router.PLUSPOINT);
                setIsMobileMenuVisible(false);
              }}
            >
              <PointIcon />
              <div className="grow text-base font-bold leading-5 text-white">
                {Helpers.convertNumberFormat(points)}
              </div>
              <Button
                size="small"
                className="max-w-40 overflow-hidden border-none bg-[#2CFF091D] text-[#2CFF09]"
              >
                <FirebaseKey
                  firebaseKey={FirebaseService.hamburger_puan_kullan_buton}
                />
              </Button>
            </div>
            <div className="mb-6">
              <UpgradeBanner activeSubs={me.activeSubs} />
            </div>
            <Row gutter={12} className="mb-6 text-white">
              <Col span={8}>
                <Button
                  block
                  type="link"
                  disabled={
                    activeQuizState.error?.message === Errors.noMoreQuiz
                  }
                  className={twMerge(
                    menuClassNames(MenuType.poll),
                    'relative',
                    activeQuizState.error?.message === Errors.noMoreQuiz
                      ? 'border-[#3F3F3F] bg-white/5 text-white/30'
                      : 'border border-[#005476] bg-[#0091F714] text-white'
                  )}
                  onClick={() => {
                    navigate(router.POLL);
                    handleClickMenu(MenuType.poll);
                  }}
                >
                  {activeQuizState.error?.message === Errors.noActiveQuiz && (
                    <div className="absolute right-2 top-2 aspect-square w-2.5 rounded-full bg-[#E5484D]"></div>
                  )}
                  <img className="h-8" src={poll} alt="game" />
                  <div className="font-medium">
                    <FirebaseKey
                      firebaseKey={FirebaseService.hamburger_anket_buton}
                    />
                  </div>
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  block
                  type="link"
                  className={twMerge(
                    menuClassNames(MenuType.game),
                    'border border-[#005476] bg-[#0091F714] text-white'
                  )}
                  onClick={() => {
                    navigate(router.TRIVIA);
                    handleClickMenu(MenuType.game);
                  }}
                >
                  <img className="h-8" src={game} alt="game" />
                  <div className="font-medium">
                    <FirebaseKey
                      firebaseKey={FirebaseService.hamburger_oyun_buton}
                    />
                  </div>
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  block
                  type="link"
                  className={twMerge(
                    menuClassNames(MenuType.wheel),
                    `border border-[#005476] bg-[#0091F714] text-white`
                  )}
                  onClick={() => {
                    navigate(router.WHEEL);
                    handleClickMenu(MenuType.wheel);
                  }}
                >
                  <img className="h-8" src={wheel} alt="wheel" />
                  <div className="font-medium">
                    <FirebaseKey
                      firebaseKey={FirebaseService.hamburger_cark_buton}
                    />
                  </div>
                </Button>
              </Col>
            </Row>
            {/* <Col span={12}>
            <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.qr}
              handleClickMenu={handleClickMenu}
            />
          </Col> */}
            <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.home}
              link={router.HOME}
              handleClickMenu={handleClickMenu}
            />
            {/* <Col span={24}>
            <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.team}
              handleClickMenu={handleClickMenu}
            />
          </Col> */}
            {/* <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.point}
              link={router.PLUSPOINT}
              handleClickMenu={handleClickMenu}
            /> */}
            <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.pointBoard}
              link={router.LEADERBOARD}
              handleClickMenu={handleClickMenu}
            />
            <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.benefits}
              link={router.BENEFITS}
              handleClickMenu={handleClickMenu}
            />
            <LeftMenuItem
              selectedMenu={selectedMenu}
              menuType={MenuType.announcements}
              link={router.ANNOUNCEMENTS}
              handleClickMenu={handleClickMenu}
            />
          </div>
          <div className="flex items-center justify-between gap-3">
            <div className="flex items-center gap-3">
              <Button
                type="link"
                className="h-10 w-10 p-0"
                icon={<HelpIcon />}
                onClick={() => navigate(router.FAQ)}
              />
              <Button
                type="link"
                className="h-10 w-10 p-0"
                icon={<MailIcon />}
                onClick={() => navigate(router.CONTACTUS)}
              />
              <Button
                type="link"
                className="h-10 w-10 p-0"
                icon={<ShareIcon />}
                onClick={() => navigate(router.INVITE)}
              />
              {/* <Badge dot>
                <Tooltip
                  className="h-10 w-10 p-0"
                  placement="top"
                  open={isShareTooltipOpen}
                  title={
                    <div>
                      <CopyOutlined className="text-white mr-1" />
                      <span>{copyText}</span>
                    </div>
                  }
                  overlayClassName="share-tooltip"
                  arrow={false}
                >
                  <CopyToClipboard
                    text={window.location.origin}
                    onCopy={onCopy}
                  >
                    <Button
                      type="link"
                      icon={<img className="w-full" src={share} alt="share" />}
                      onMouseOver={() => setIsShareTooltipOpen(true)}
                      onMouseLeave={() => setIsShareTooltipOpen(false)}
                    />
                  </CopyToClipboard>
                </Tooltip>
              </Badge> */}
            </div>
            <Button
              type="link"
              className="h-10 w-10 p-0"
              icon={<LogoutIcon />}
              onClick={handleLogoutButton}
            />
          </div>
        </div>
      );
    }
  }

  function renderMobileHeader(): ReactNode {
    if (isMobileMenuVisible) {
      return (
        <div className="flex flex-grow justify-center pr-10">
          <Link to={router.HOME}>
            <img className="w-44" src={mobileLogo} alt="logo" />
          </Link>
        </div>
      );
    }

    return (
      <div className="flex flex-grow items-center justify-end gap-2">
        <Button
          disabled={activeQuizState.error?.message === Errors.noMoreQuiz}
          onClick={() => navigate(router.POLL)}
          className="mobile-header-btn relative"
        >
          {activeQuizState.error?.message === Errors.noActiveQuiz && (
            <div className="absolute right-0.5 top-0.5 aspect-square w-2.5 rounded-full bg-[#E5484D]"></div>
          )}
          <img className="h-5" src={poll} alt="wheel" />
        </Button>
        <Button
          onClick={() => navigate(router.TRIVIA)}
          className="mobile-header-btn"
        >
          <img className="h-5" src={game} alt="game" />
        </Button>
        <Button
          onClick={() => navigate(router.WHEEL)}
          className="mobile-header-btn"
        >
          <img className="h-5" src={wheel} alt="wheel" />
        </Button>
        <Button
          className={`mobile-header-btn ml-2 p-1 pb-0 ${
            selectedMenu === MenuType.profile ? 'border-green' : ''
          }`}
          onClick={() => navigate(router.PROFILE)}
        >
          <img
            className="max-w-full rounded-md"
            src={
              me?.avatarImageUrl ? me.avatarImageUrl : Constants.defaultAvatar
            }
            alt="avatar"
          />
        </Button>
      </div>
    );
  }

  function renderMobileMenu(): ReactNode {
    return (
      <div id="app-menu" className="relative z-50">
        <Layout.Header className="flex items-center bg-black/70 px-4 shadow-mobileHeader backdrop-blur-md md:hidden">
          <Button
            className="mobile-header-btn"
            icon={<Icon component={MenuIcon} />}
            onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
          />
          {renderMobileHeader()}
        </Layout.Header>
        {isMobileMenuVisible && (
          <div className="fixed z-10 flex h-[calc(100dvh-64px)] w-full flex-col overflow-y-auto bg-black/70 p-4 backdrop-blur-md">
            {renderMenuContent()}
          </div>
        )}
      </div>
    );
  }

  function renderDesktopMenu(): ReactNode {
    return (
      <Layout.Sider
        id="app-menu"
        width={284}
        className="hidden font-medium md:block"
      >
        <div className="flex h-full flex-col">
          <div className="p-4 pb-6">
            <Link to={router.HOME}>
              <img className="w-36" src={logo} alt="logo" />
            </Link>
          </div>
          {renderMenuContent()}
        </div>
      </Layout.Sider>
    );
  }

  function renderContent(): ReactNode {
    return windowSize.width < 768 ? renderMobileMenu() : renderDesktopMenu();
  }

  return (
    <>
      {renderContent()}
      <AreYouSureModal
        isVisible={isLogoutModalVisible}
        isError={true}
        titleKey={FirebaseService.logout_popup_title}
        descKey={FirebaseService.logout_popup_desc}
        iconSrc={logoutIcon}
        cancelTextKey={FirebaseService.logout_popup_cancel_button}
        confirmTextKey={FirebaseService.logout_popup_exit_button}
        handleCancel={() => setIsLogoutModalVisible(false)}
        handleConfirm={handleLogout}
      />
    </>
  );
}

function UpgradeBanner({
  activeSubs,
}: {
  activeSubs: PackageDto[] | undefined;
}) {
  const navigate = useNavigate();
  if (activeSubs?.length) {
    return null;
  }
  return (
    <div className="relative flex gap-1 overflow-hidden rounded-md bg-[#002C40] p-4">
      <img
        src={subscriptionCard}
        className="absolute inset-0 h-full w-full object-cover object-center"
        alt=""
      />
      <div className="z-10 flex w-32 grow flex-col gap-1">
        <div className="break-word text-white">
          {activeSubs?.length ? (
            <FirebaseKey firebaseKey={FirebaseService.upgrade_vip_header} />
          ) : (
            <FirebaseKey firebaseKey={FirebaseService.buy_vip_header} />
          )}
        </div>
        <div className="flex break-word text-white/50">
          {activeSubs?.length ? (
            <FirebaseKey
              firebaseKey={FirebaseService.upgrade_vip_description}
            />
          ) : (
            <FirebaseKey firebaseKey={FirebaseService.buy_vip_description} />
          )}
        </div>
      </div>
      <div className="z-10 flex w-20 grow items-end">
        <Button
          onClick={() => navigate('/subscription')}
          className="overflow-hidden bg-[#00C5FF] hover:text-black"
        >
          {activeSubs?.length ? (
            <FirebaseKey firebaseKey={FirebaseService.upgrade_vip_button} />
          ) : (
            <FirebaseKey firebaseKey={FirebaseService.buy_vip_button} />
          )}
        </Button>
      </div>
    </div>
  );
}

export default AppMenu;
