import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CityDto } from '../../../models/dtos/city.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getUserCitiesRequest from './getUserCities.repository';

type InitialState = {
  loading: boolean;
  data?: CityDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getUserCities = createAsyncThunk(
  'getUserCities',
  (countryName: string) => {
    return getUserCitiesRequest(countryName);
  }
);

const getUserCitiesSlice = createSlice({
  name: 'getUserCities',
  initialState,
  reducers: {
    getUserCitiesReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCities.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getUserCities.fulfilled,
      (state, action: PayloadAction<CityDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getUserCities.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getUserCitiesSlice.reducer;
export const { getUserCitiesReset } = getUserCitiesSlice.actions;
