import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { CommentRequest } from '../../../models/requests/comment.request';
import { Errors } from '../../errors';
import sendCommentRequest from './sendComment.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const sendComment = createAsyncThunk(
  'sendComment',
  async (comment: CommentRequest, { rejectWithValue }) => {
    try {
      return await sendCommentRequest(comment);
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);

const sendCommentSlice = createSlice({
  name: 'sendComment',
  initialState,
  reducers: {
    sendCommentReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendComment.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      sendComment.fulfilled,
      (state, action: PayloadAction<void>) => {
        state.loading = false;
        state.data = true;
      }
    );
    builder.addCase(sendComment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default sendCommentSlice.reducer;
export const { sendCommentReset } = sendCommentSlice.actions;
