import { AnnouncementDto } from '../../../models/dtos/announcement.dto';
import axios from '../../axios';

export default async function getAnnouncementsRequest(
  teamId?: number
): Promise<AnnouncementDto[]> {
  const response = await axios.get<AnnouncementDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/announcement`,
    {
      params: {
        teamId,
      },
    }
  );
  return response.data;
}
