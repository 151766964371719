import { ContactUsRequest } from '../../../models/requests/contactUs.request';
import axios from '../../axios';

export async function submitContactUsRequest(
  request: ContactUsRequest
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/contact-us`,
    request
  );
  return response.data;
}
