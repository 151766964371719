import { CommentRequest } from '../../../models/requests/comment.request';
import axios from '../../axios';

export default async function sendCommentRequest(
  comment: CommentRequest
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/users/comment`,
    comment
  );
  return response.data;
}
