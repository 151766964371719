import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { LeaderboardAllDto } from '../../../models/dtos/leaderboardAll.dto';
import { Errors } from '../../errors';
import getLeaderboardAllTribunesRequest from './getLeaderboardAllTribunes.repository';

type InitialState = {
  loading: boolean;
  data?: LeaderboardAllDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getLeaderboardAllTribunes = createAsyncThunk(
  'getLeaderboardAllTribunes',
  async () => {
    return await getLeaderboardAllTribunesRequest();
  }
);

const getLeaderboardAllTribunesSlice = createSlice({
  name: 'getLeaderboardAllTribunes',
  initialState,
  reducers: {
    getLeaderboardAllTribunesReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaderboardAllTribunes.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getLeaderboardAllTribunes.fulfilled,
      (state, action: PayloadAction<LeaderboardAllDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getLeaderboardAllTribunes.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getLeaderboardAllTribunesSlice.reducer;
export const { getLeaderboardAllTribunesReset } = getLeaderboardAllTribunesSlice.actions;
