import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BenefitDto } from '../../../models/dtos/benefit.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import getBenefitsRequest from './getBenefits.repository';

type InitialState = {
  loading: boolean;
  data?: BenefitDto[];
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getBenefits = createAsyncThunk('getBenefits', async () => {
  return await getBenefitsRequest();
});

const getBenefitsSlice = createSlice({
  name: 'getBenefits',
  initialState,
  reducers: {
    getBenefitsReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBenefits.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getBenefits.fulfilled,
      (state, action: PayloadAction<BenefitDto[]>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getBenefits.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default getBenefitsSlice.reducer;
export const { getBenefitsReset } = getBenefitsSlice.actions;
