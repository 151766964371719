import { ReactNode, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { loginReset } from '../../../core/services/auth/login/login.slice';
import { otpReset } from '../../../core/services/auth/otp/otp.slice';
import FirebaseService from '../../../core/services/firebase/firebaseService';
import { useAppDispatch, useAppSelector } from '../../../core/services/hooks';
import { sendNicknameReset } from '../../../core/services/user/sendNickname/sendNickname.slice';
import { router } from '../../../core/utilities/router';
import CookieAgreement from '../../CookieAgreement/CookieAgreement';
import Footer from '../../Footer/Footer';
import Header from '../../Landing/Header';
import './AuthLayout.scss';

function AuthLayout() {
  const getUserInfoState = useAppSelector((state) => state.user.getUserInfo);
  const otpState = useAppSelector((state) => state.auth.otp);
  const sendNicknameState = useAppSelector((state) => state.user.sendNickname);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(loginReset());
    };
  }, [dispatch]);

  useEffect(() => {
    const { loading, data } = getUserInfoState;
    if ((otpState.data || sendNicknameState.data) && !loading && data) {
      dispatch(sendNicknameReset());
      dispatch(otpReset());
      if (!data.nickname) {
        navigate(router.NICKNAME);
        return;
      }
      if (!data.team) {
        navigate(router.CHOOSETEAM);
      }
    }
    // eslint-disable-next-line
  }, [dispatch, navigate, getUserInfoState]);

  function renderImage(): ReactNode {
    const bannerImages = FirebaseService.getBannerImages();
    const imageSrc = bannerImages[(Math.random() * bannerImages.length) | 0];

    return (
      <>
        <img
          className="hidden md:block max-w-[55%] h-5/6 max-h-[75vh] aspect-[832/816] rounded-bl-40 shadow-authBanner"
          src={imageSrc}
          alt="banner"
        />
        <img
          className="md:hidden w-full rounded-b-20"
          src={imageSrc}
          alt="banner"
        />
      </>
    );
  }

  return (
    <div className="flex min-h-screen flex-col bg-white">
      <Header />
      <div className="flex flex-grow flex-col md:flex-row-reverse pb-8">
        {renderImage()}
        <div className="md:flex-grow border md:border-0 border-solid border-borderColor shadow-box md:shadow-none rounded-20 mt-6 mx-4 p-4 md:px-8 lg:px-16 md:pt-[8vh]">
          <div className="md:max-w-lg mx-auto">
            <img className="hidden md:block w-44 mb-16" src={logo} alt="logo" />
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
      <CookieAgreement />
    </div>
  );
}

export default AuthLayout;
