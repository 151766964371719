import { MobilePaymentDto } from '../../../models/dtos/mobilePayment.dto';
import { MobilePaymentRequest } from '../../../models/requests/mobilePayment.request';
import axios from '../../axios';

export default async function mobilePaymentStartRequest(
  request: MobilePaymentRequest
): Promise<MobilePaymentDto> {
  const response = await axios.post<MobilePaymentDto>(
    `${process.env.REACT_APP_BASE_URL}/payment/mobile/start`,
    request
  );
  return response.data;
}
