import { LeaderboardDto } from '../../../models/dtos/leaderboard.dto';
import axios from '../../axios';

export default async function getLeaderboardUserRequest(
  teamId?: number
): Promise<LeaderboardDto> {
  const response = await axios.get<LeaderboardDto>(
    `${process.env.REACT_APP_BASE_URL}/leaderboard/user`,
    {
      params: {
        teamId,
      },
    }
  );
  return response.data;
}
