import { TriviaQuestionDto } from '../../../models/dtos/triviaQuestion.dto';
import axios from '../../axios';

export default async function getTriviaQuestionRequest({
  teamId,
  lastId,
}: {
  teamId?: number;
  lastId?: number;
}): Promise<TriviaQuestionDto> {
  const response = await axios.get<TriviaQuestionDto>(
    `${process.env.REACT_APP_BASE_URL}/users/trivia`,
    {
      params: {
        teamId,
        lastId,
      },
    }
  );
  return response.data;
}
