export const router = {
  LANDING: '/',
  HOME: '/home',
  LOGIN: '/login',
  NICKNAME: '/nickname',
  CHOOSETEAM: '/choose-team',
  SUBSCRIPTION: '/subscription',
  CONTACTUS: '/contact-us',
  ANNOUNCEMENTS: '/announcements',
  FAQ: '/faq',
  LEADERBOARD: '/leaderboard',
  PROFILE: '/profile',
  POLL: '/poll',
  WHEEL: '/wheel',
  PAYMENTSUCCESS: '/payment-redirect',
  PAYMENTFAILED: '/payment-failed',
  BENEFITS: '/benefits',
  INVITE: '/invite',
  PLUSPOINT: '/plus-point',
  CONVERTPOINTS: '/convert-points',
  TOKEN: '/token',
  TRIVIA: '/trivia',
};
