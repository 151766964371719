import { TriviaQuestionAnswerDto } from '../../../models/dtos/triviaQuestionAnswer.dto';
import { TriviaQuestionAnswerRequest } from '../../../models/requests/triviaQuestionAnswer.request';
import axios from '../../axios';

export default async function sendTriviaQuestionAnswerRequest(
  request: TriviaQuestionAnswerRequest
): Promise<TriviaQuestionAnswerDto> {
  const response = await axios.post<TriviaQuestionAnswerDto>(
    `${process.env.REACT_APP_BASE_URL}/users/trivia`,
    request
  );
  return response.data;
}
