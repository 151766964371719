import { Navigate, Outlet } from 'react-router-dom';

import { Layout } from 'antd';
import { useAppSelector } from '../../../core/services/hooks';
import CookieAgreement from '../../CookieAgreement/CookieAgreement';
import Footer from '../../Footer/Footer';
import Header from '../../Landing/Header';

function LandingLayout() {
  const me = useAppSelector((state) => state.app.setMe.me);

  if (me) {
    return <Navigate to={'/home'} replace={true} />;
  }

  return (
    <Layout className="bg-white">
      <Header />
      <Outlet />
      <Footer />
      <CookieAgreement />
    </Layout>
  );
}

export default LandingLayout;
