const TeamIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79916 2.29103C7.92027 2.21028 8.07806 2.21028 8.19918 2.29103C9.59689 3.22283 11.1085 3.80422 12.7376 4.03695C12.9152 4.06232 13.0472 4.21446 13.0472 4.3939V7.63904C13.0472 10.4452 11.3835 12.4928 8.1286 13.7447C8.04529 13.7768 7.95305 13.7768 7.86973 13.7447C4.61484 12.4928 2.95117 10.4452 2.95117 7.63904V4.3939C2.95117 4.21446 3.08312 4.06232 3.26075 4.03695C4.88984 3.80422 6.40145 3.22283 7.79916 2.29103Z"
        fill="white"
        fillOpacity="0.926"
      />
    </svg>
  );
};

export default TeamIcon;
