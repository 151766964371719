import { MobilePaymentCheckDto } from '../../../models/dtos/mobilePaymentCheck.dto';
import axios from '../../axios';

export default async function mobilePaymentCheckRequest(
  smsCid: string
): Promise<MobilePaymentCheckDto> {
  const response = await axios.get<MobilePaymentCheckDto>(
    `${process.env.REACT_APP_BASE_URL}/payment/mobile/status`,
    {
      params: {
        smsCid,
      },
    }
  );
  return response.data;
}
