import { BannerDto } from '../../../models/dtos/banner.dto';
import axios from '../../axios';

export default async function getBannersRequest(
  teamId: number
): Promise<BannerDto[]> {
  const response = await axios.get<BannerDto[]>(
    `${process.env.REACT_APP_BASE_URL}/users/banner`,
    {
      params: {
        teamId,
      },
    }
  );
  return response.data;
}
