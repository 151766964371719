import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { OfferRequest } from '../../../models/requests/offer.request';
import { Errors } from '../../errors';
import purchaseOfferRequest from './purchaseOffer.repository';

type InitialState = {
  loading: boolean;
  data?: string;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const purchaseOffer = createAsyncThunk(
  'purchaseOffer',
  async (request: OfferRequest, { rejectWithValue }) => {
    try {
      return await purchaseOfferRequest(request);
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);

const purchaseOfferSlice = createSlice({
  name: 'purchaseOffer',
  initialState,
  reducers: {
    purchaseOfferReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purchaseOffer.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      purchaseOffer.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(purchaseOffer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default purchaseOfferSlice.reducer;
export const { purchaseOfferReset } = purchaseOfferSlice.actions;
