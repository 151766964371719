import { Button, Col, Row, notification } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FirebaseService from '../../core/services/firebase/firebaseService';
import Constants from '../../core/utilities/constants';
import Helpers from '../../core/utilities/helpers';
import { LocalStorage } from '../../core/utilities/storage';
import FirebaseKey from '../FirebaseKey/FirebaseKey';
import './CookieAgreement.scss';

function CookieAgreement() {
  const [api, contextHolder] = notification.useNotification();

  const location = useLocation();

  useEffect(() => {
    const cookie = LocalStorage.get(LocalStorage.cookieAgreement);
    const token = Helpers.getQueryParam(location, LocalStorage.token);
    if (!cookie && !token) {
      api.open({
        placement: 'bottomRight',
        closeIcon: false,
        message: (
          <p className="text-white text-xl font-bold mt-0 mb-4">
            <FirebaseKey
              firebaseKey={FirebaseService.landing_cookie_popup_desc}
            />
          </p>
        ),
        description: (
          <Row gutter={12}>
            <Col span={12}>
              <Button
                block
                className="h-10 bg-blue-btn rounded-lg border-0 text-black text-base font-medium"
                onClick={handleAcceptCookie}
              >
                <FirebaseKey
                  firebaseKey={
                    FirebaseService.landing_cookie_popup_accept_button
                  }
                />
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                type="link"
                className="h-10 text-white text-base font-medium"
                onClick={() => window.open(Constants.cookiePolicy, '_blank')}
              >
                <FirebaseKey
                  firebaseKey={
                    FirebaseService.landing_cookie_popup_read_more_button
                  }
                />
              </Button>
            </Col>
          </Row>
        ),
        className: 'p-4',
        duration: 0,
        style: {
          width: 360,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleAcceptCookie = () => {
    LocalStorage.set(LocalStorage.cookieAgreement, 'true');
    api.destroy();
  };

  return <>{contextHolder}</>;
}

export default CookieAgreement;
