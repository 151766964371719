import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { MobilePaymentDto } from '../../../models/dtos/mobilePayment.dto';
import { MobilePaymentRequest } from '../../../models/requests/mobilePayment.request';
import { Errors } from '../../errors';
import mobilePaymentStartRequest from './mobilePaymentStart.repository';

type InitialState = {
  loading: boolean;
  data?: MobilePaymentDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const mobilePaymentStart = createAsyncThunk(
  'mobilePaymentStart',
  async (request: MobilePaymentRequest, { dispatch }) => {
    return await mobilePaymentStartRequest(request);
  }
);

const mobilePaymentStartSlice = createSlice({
  name: 'mobilePaymentStart',
  initialState,
  reducers: {
    mobilePaymentStartReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mobilePaymentStart.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      mobilePaymentStart.fulfilled,
      (state, action: PayloadAction<MobilePaymentDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(mobilePaymentStart.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default mobilePaymentStartSlice.reducer;
export const { mobilePaymentStartReset } = mobilePaymentStartSlice.actions;
