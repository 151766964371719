const MailIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.2 2.39844C0.537258 2.39844 0 2.9357 0 3.59844V14.3984C0 15.0612 0.537258 15.5984 1.2 15.5984H16.8C17.4628 15.5984 18 15.0612 18 14.3984V3.59844C18 2.9357 17.4628 2.39844 16.8 2.39844H1.2ZM1.2 3.59844H16.8V4.70837C16.7009 4.70827 16.6006 4.73545 16.5107 4.7925L9 9.55887L1.48934 4.7925C1.39945 4.73545 1.29912 4.70827 1.2 4.70837V3.59844ZM1.2 5.888V14.3984H16.8V5.888L9.28934 10.6544C9.11273 10.7665 8.88727 10.7665 8.71066 10.6544L1.2 5.888Z"
        fill="#B1B1B1"
      />
    </svg>
  );
};

export default MailIcon;
