import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { QuizDto } from '../../../models/dtos/quiz.dto';
import { Errors } from '../../errors';
import getActiveQuizRequest from './getActiveQuiz.repository';

type InitialState = {
  loading: boolean;
  data?: QuizDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const getActiveQuiz = createAsyncThunk(
  'getActiveQuiz',
  async (_, { rejectWithValue }) => {
    try {
      return await getActiveQuizRequest();
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);

const getActiveQuizSlice = createSlice({
  name: 'getActiveQuiz',
  initialState,
  reducers: {
    getActiveQuizReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveQuiz.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      getActiveQuiz.fulfilled,
      (state, action: PayloadAction<QuizDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getActiveQuiz.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default getActiveQuizSlice.reducer;
export const { getActiveQuizReset } = getActiveQuizSlice.actions;
