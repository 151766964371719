const HelpIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.05273 8.99818C1.05273 4.60896 4.61091 1.05078 9.00014 1.05078C13.3894 1.05078 16.9475 4.60896 16.9475 8.99818C16.9475 13.3874 13.3894 16.9456 9.00014 16.9456C4.61091 16.9456 1.05273 13.3874 1.05273 8.99818ZM9.00014 2.19078C5.24051 2.19078 2.19274 5.23856 2.19274 8.99818C2.19274 12.7578 5.24051 15.8056 9.00014 15.8056C12.7597 15.8056 15.8075 12.7578 15.8075 8.99818C15.8075 5.23856 12.7597 2.19078 9.00014 2.19078ZM9.90016 12.5985C9.90016 13.0956 9.49721 13.4985 9.00016 13.4985C8.5031 13.4985 8.10016 13.0956 8.10016 12.5985C8.10016 12.1015 8.5031 11.6985 9.00016 11.6985C9.49721 11.6985 9.90016 12.1015 9.90016 12.5985ZM7.26028 7.49851C7.26028 6.68504 7.96238 5.90851 9.00028 5.90851C10.0382 5.90851 10.7403 6.68504 10.7403 7.49851C10.7403 8.08793 10.4163 8.38906 9.85761 8.72844C9.79526 8.76632 9.72334 8.80808 9.64641 8.85276C9.42657 8.98043 9.1657 9.13194 8.96963 9.2847C8.67881 9.5113 8.34028 9.87689 8.34028 10.4385C8.34029 10.803 8.63578 11.0985 9.00029 11.0985C9.36312 11.0985 9.65758 10.8057 9.66027 10.4435L9.66092 10.4425C9.67133 10.426 9.70216 10.3873 9.78094 10.326C9.90273 10.2311 10.0383 10.1525 10.219 10.0477C10.3133 9.99314 10.4198 9.9314 10.543 9.85658C11.1843 9.46697 12.0602 8.82309 12.0602 7.49851C12.0602 5.91198 10.7224 4.58851 9.00028 4.58851C7.2782 4.58851 5.94028 5.91198 5.94028 7.49851C5.94028 7.86302 6.23578 8.15851 6.60028 8.15851C6.96479 8.15851 7.26028 7.86302 7.26028 7.49851Z"
        fill="#B1B1B1"
      />
    </svg>
  );
};

export default HelpIcon;
